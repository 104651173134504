// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-default-page-js": () => import("/opt/build/repo/src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-components-page-js": () => import("/opt/build/repo/src/templates/ComponentsPage.js" /* webpackChunkName: "component---src-templates-components-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-blog-index-js": () => import("/opt/build/repo/src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-home-page-js": () => import("/opt/build/repo/src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-single-post-js": () => import("/opt/build/repo/src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

